import React, { useContext } from "react";
import emoji from "react-easy-emoji";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import "./Footer.css";

export default function Footer() {
  const { isDark } = useContext(StyleContext);
  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div">
        
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          After-school Clubs{" "}
          <h3><a href="https://calendly.com/pairsession/20mins">
            For Schools
          </a></h3>
        </p>
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          {emoji("Classvisa | 2022")}
        </p>
      </div>
    </Fade>
  );
}
