import React from "react";
import "./TalkCard.css";
// import HorizontalScroller from 'react-horizontal-scroll-container';
// import ScrollMenu from 'react-horizontal-scrolling-menu';

export default function TalkCard({talkDetails}) {
  return (
    // <HorizontalScroller>
    <div className="column">
     
      <div className="container">
      
        <div
          className={
            talkDetails.isDark ? "dark-rectangle rectangle" : "rectangle"
          }
        >
          <div className="horizontal-fill"><div/>
          <div className="talk-card-title">{talkDetails.title}</div>
          <p className="talk-card-subtitle">{talkDetails.subtitle}</p>

          <div className="card-footer-button-div">
            <a href={talkDetails.slides_url} target="_" className="talk-button">
              Coding Challenge
            </a>
            <a href={talkDetails.event_url} target="_" className="talk-button">
              Submit Here
            </a>
          </div>
        </div>
     </div>
     
     </div>
     
    </div>
    // </HorizontalScroller>
  );
}
